// スライダー
$(function(){
	$('.new-mainvisual__list.--pc').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true
	});
	$('.new-mainvisual__list.--sp').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true
	});
	$('.js-slider').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true,
	});
	$('.js-pickupSlider').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true,
	});
	$('.js-menuSlider').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true,
	});
	$('.js-serviceSlider').slick({
		// スライダーの自動切り替え
		autoplay: true,
		// 要素を中央に寄せる
		centerMode: true,
		// スライダーの下のページャー
		dots: true,
		// フォーカス時にスライダーを停止
		pauseOnFocus: true,
		// マウスホバー時にスライダーを停止
		pauseOnHover: true,
		// 一度にスクロールする画像の枚数
		slidesToScroll: 1,
		// 一度に表示する画像の枚数
		slidesToShow: 1,
		// スライダーアイテムの横幅をcssで制御
		variableWidth: true,
	});
});

// 続きを読むボタン
$(document).ready(function(){
// span.new-section__articleListMoreがクリックされたときの処理
$('span.new-section__articleListMore').click(function(){
  // .new-section__articleListのheightをautoに設定
  $('.new-section__articleList').css('height', 'auto');
  
  // span.new-section__articleListMoreを非表示にする
  $(this).hide();
});
});

// SP表示時footer用
$(document).ready(function(){
// ウィンドウの幅が768px以下の場合のみ処理を実行
if ($(window).width() <= 768) {
  // span.new-footer__titleがクリックされたときの処理
  $('span.new-footer__title').click(function(){
    // 次の要素をslideToggle
    $(this).next().slideToggle();

    // span.new-footer__titleに--activeクラスを追加
    $(this).toggleClass('--active');
  });
}

// ウィンドウのリサイズ時にもチェック
$(window).resize(function(){
  // ウィンドウの幅が768px以下ならクリックでslideToggle
  if ($(window).width() <= 768) {
    // span.new-footer__titleがクリックされたときの処理
    $('span.new-footer__title').off('click'); // クリックイベントを一旦削除
    $('span.new-footer__title').click(function(){
      // 次の要素をslideToggle
      $(this).next().slideToggle();

      // span.new-footer__titleに--activeクラスを追加
      $(this).toggleClass('--active');
    });
  } else {
    // ウィンドウの幅が768px以上ならクリックでの処理を削除
    $('span.new-footer__title').off('click');
  }
});
});

// SPメニュー
$(document).ready(function(){
// .new-headerSpBtnがクリックされたときの処理
$('.new-headerSpBtn').click(function(){
  // .new-headerNevをslideToggle
  $('.new-headerNev').fadeToggle();

  // .new-headerSpBtnに--activeクラスを追加
  $(this).toggleClass('--active');
});
});


// トグル
$(function(){
	$('.js-toggle').click(function() {
		$(this).toggleClass('--active');
		$(this).next('.js-togglContents').slideToggle();
	});
	$('.new-storeList__areaTitle').click(function() {
		$(this).toggleClass('--active');
		$(this).next('.new-storeList__storeList').slideToggle();
	});
});

// ギャラリーカルーセル
$(document).ready(function(){
  $('.new-imageGallery__list').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    speed: 5000,
    cssEase: 'linear',
    arrows: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 768, // 768px以下の場合の設定
        settings: {
          slidesToShow: 3,
        }
      }
      // 他にも必要ならば、さらにresponsiveオプションを追加する
    ]
  });
});

// グローバルメニュー
$(document).ready(function () {
  // 横幅が768px以上の場合
  if ($(window).width() > 768) {
    // メニュー項目がホバーされたときの処理
    $('.new-headerNev__item').hover(
      function () {
        // ホバーされたメニュー項目のサブメニューを表示
        $(this).find('.new-headerNev__subList').stop().slideDown();
      },
      function () {
        // ホバーが外れたときにサブメニューを非表示
        $(this).find('.new-headerNev__subList').stop().slideUp();
      }
    );
  } else {
    // 横幅が768px以下の場合
    // メニュー項目がクリックされたときの処理
    $('.new-headerNev__item .new-headerNev__link').click(function (e) {
      // hrefが#以外の場合は通常のリンクとして遷移
      if ($(this).attr('href') !== '#') {
        return;
      }

      // デフォルトの動作をキャンセル
      e.preventDefault();
      // クリックされたメニュー項目のサブメニューをトグル表示・非表示
      $(this).parent().find('.new-headerNev__subList').stop().slideToggle();
      $(this).toggleClass('--active');
    });
  }

  // ウィンドウのリサイズ時にもチェック
  $(window).resize(function () {
    // リサイズ時に横幅が768px以上ならホバー、以下ならクリックでトグル
    if ($(window).width() > 768) {
      // メニュー項目がホバーされたときの処理
      $('.new-headerNev__item').off('click'); // クリックイベントを一旦削除
      $('.new-headerNev__item').hover(
        function () {
          // ホバーされたメニュー項目のサブメニューを表示
          $(this).find('.new-headerNev__subList').stop().slideDown();
        },
        function () {
          // ホバーが外れたときにサブメニューを非表示
          $(this).find('.new-headerNev__subList').stop().slideUp();
        }
      );
    } else {
      // メニュー項目がクリックされたときの処理
      $('.new-headerNev__item').off('mouseenter mouseleave'); // ホバーイベントを一旦削除
      $('.new-headerNev__item .new-headerNev__link').off('click'); // クリックイベントを一旦削除
      $('.new-headerNev__item .new-headerNev__link').click(function (e) {
        // hrefが#以外の場合は通常のリンクとして遷移
        if ($(this).attr('href') !== '#') {
          return;
        }

        // デフォルトの動作をキャンセル
        e.preventDefault();
        // クリックされたメニュー項目のサブメニューをトグル表示・非表示
        $(this).parent().find('.new-headerNev__subList').stop().slideToggle();
      });
    }
  });
});

// 検索ボタン
$(document).ready(function(){
// 横幅が768px以上の場合のみ処理を実行
if ($(window).width() > 768) {
  // ボタンがクリックされたときの処理
  $('.new-headerTop__searchBtn').click(function(){
    // 検索フォームを開閉する
    $('.new-headerTop__searchForm').fadeToggle();

    // クリックされたボタンに--activeクラスを追加・削除
    $(this).toggleClass('--active');
  });
}

// ウィンドウのリサイズ時にもチェック
$(window).resize(function(){
  // 横幅が768px以上ならボタンクリックで開閉
  if ($(window).width() > 768) {
    // ボタンがクリックされたときの処理
    $('.new-headerTop__searchBtn').off('click'); // クリックイベントを一旦削除
    $('.new-headerTop__searchBtn').click(function(){
      // 検索フォームを開閉する
      $('.new-headerTop__searchForm').fadeToggle();

      // クリックされたボタンに--activeクラスを追加・削除
      $(this).toggleClass('--active');
    });
  } else {
    // 横幅が768px以下なら検索フォームを隠し、--activeクラスを削除
    $('.new-headerTop__searchForm').hide();
    $('.new-headerTop__searchBtn').removeClass('--active');
  }
});
});

// フッターショップリスト用
$(document).ready(function () {
  if ($(window).width() <= 768) {
    // ウィンドウの幅が768px以下の場合のみ処理を実行

    // .new-footerStoreList__areaTitleがクリックされたときの処理
    $('.new-footerStoreList__areaTitle').click(function () {
      // .new-footerStoreList__footerStoreListをslideToggle
      $(this).next('.new-footerStoreList__footerStoreList').stop().slideToggle();

      // .new-footerStoreList__areaTitleに.--activeクラスを追加
      $(this).toggleClass('--active');
    });
  }
});

/*
* jquery-match-height 0.7.2 by @liabru
* http://brm.io/jquery-match-height/
* License MIT
*/
!function(t){"use strict";"function"==typeof define&&define.amd?define(["jquery"],t):"undefined"!=typeof module&&module.exports?module.exports=t(require("jquery")):t(jQuery)}(function(t){var e=-1,o=-1,n=function(t){return parseFloat(t)||0},a=function(e){var o=1,a=t(e),i=null,r=[];return a.each(function(){var e=t(this),a=e.offset().top-n(e.css("margin-top")),s=r.length>0?r[r.length-1]:null;null===s?r.push(e):Math.floor(Math.abs(i-a))<=o?r[r.length-1]=s.add(e):r.push(e),i=a}),r},i=function(e){var o={
byRow:!0,property:"height",target:null,remove:!1};return"object"==typeof e?t.extend(o,e):("boolean"==typeof e?o.byRow=e:"remove"===e&&(o.remove=!0),o)},r=t.fn.matchHeight=function(e){var o=i(e);if(o.remove){var n=this;return this.css(o.property,""),t.each(r._groups,function(t,e){e.elements=e.elements.not(n)}),this}return this.length<=1&&!o.target?this:(r._groups.push({elements:this,options:o}),r._apply(this,o),this)};r.version="0.7.2",r._groups=[],r._throttle=80,r._maintainScroll=!1,r._beforeUpdate=null,
r._afterUpdate=null,r._rows=a,r._parse=n,r._parseOptions=i,r._apply=function(e,o){var s=i(o),h=t(e),l=[h],c=t(window).scrollTop(),p=t("html").outerHeight(!0),u=h.parents().filter(":hidden");return u.each(function(){var e=t(this);e.data("style-cache",e.attr("style"))}),u.css("display","block"),s.byRow&&!s.target&&(h.each(function(){var e=t(this),o=e.css("display");"inline-block"!==o&&"flex"!==o&&"inline-flex"!==o&&(o="block"),e.data("style-cache",e.attr("style")),e.css({display:o,"padding-top":"0",
"padding-bottom":"0","margin-top":"0","margin-bottom":"0","border-top-width":"0","border-bottom-width":"0",height:"100px",overflow:"hidden"})}),l=a(h),h.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||"")})),t.each(l,function(e,o){var a=t(o),i=0;if(s.target)i=s.target.outerHeight(!1);else{if(s.byRow&&a.length<=1)return void a.css(s.property,"");a.each(function(){var e=t(this),o=e.attr("style"),n=e.css("display");"inline-block"!==n&&"flex"!==n&&"inline-flex"!==n&&(n="block");var a={
display:n};a[s.property]="",e.css(a),e.outerHeight(!1)>i&&(i=e.outerHeight(!1)),o?e.attr("style",o):e.css("display","")})}a.each(function(){var e=t(this),o=0;s.target&&e.is(s.target)||("border-box"!==e.css("box-sizing")&&(o+=n(e.css("border-top-width"))+n(e.css("border-bottom-width")),o+=n(e.css("padding-top"))+n(e.css("padding-bottom"))),e.css(s.property,i-o+"px"))})}),u.each(function(){var e=t(this);e.attr("style",e.data("style-cache")||null)}),r._maintainScroll&&t(window).scrollTop(c/p*t("html").outerHeight(!0)),
this},r._applyDataApi=function(){var e={};t("[data-match-height], [data-mh]").each(function(){var o=t(this),n=o.attr("data-mh")||o.attr("data-match-height");n in e?e[n]=e[n].add(o):e[n]=o}),t.each(e,function(){this.matchHeight(!0)})};var s=function(e){r._beforeUpdate&&r._beforeUpdate(e,r._groups),t.each(r._groups,function(){r._apply(this.elements,this.options)}),r._afterUpdate&&r._afterUpdate(e,r._groups)};r._update=function(n,a){if(a&&"resize"===a.type){var i=t(window).width();if(i===e)return;e=i;
}n?o===-1&&(o=setTimeout(function(){s(a),o=-1},r._throttle)):s(a)},t(r._applyDataApi);var h=t.fn.on?"on":"bind";t(window)[h]("load",function(t){r._update(!1,t)}),t(window)[h]("resize orientationchange",function(t){r._update(!0,t)})});

$(function() {
	$('.new-storeList__areaBox').matchHeight();
});